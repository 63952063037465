<template>
  <loading v-if="isLoading" />
  <overlay-component v-else-if="overlayLoading" />
  <section v-else class="container">
    <div class="cards-container" v-if="friendRequests && friendRequests.length > 0">
      <b-card v-for="item in friendRequests" :key="item.id" img-top
        :img-src="item.request.media[0] ? item.request.media[0].path : $helpers.uiAvatar(item.request.first_name)"
        :img-alt="item.request.first_name">
        <b-card-title>
          {{ `${item.request.first_name} ${item.request.last_name}` }}
        </b-card-title>
        <div class="card-actions">
          <b-button class="accept-btn" @click="acceptRequest(item.id)">
            {{ $t("confirm") }}
          </b-button>
          <b-button class="reject-btn" @click="rejectRequest(item.id)">
            {{ $t("delete") }}
          </b-button>
        </div>
      </b-card>
    </div>
    <div v-else class="no-data">{{ $t("there_is_no_friend_requests") }}</div>
  </section>
</template>

<script>
import Loading from "@/components/loading/loading.vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
import { BCard, BCardTitle, BButton } from "bootstrap-vue";

export default {
  components: {
    Loading,
    BCard,
    BCardTitle,
    BButton,
    OverlayComponent,
  },
  data() {
    return {
      isLoading: false,
      overlayLoading: false,
      friendRequests: null,
    };
  },
  created() {
    this.getFriendRequests();
  },
  methods: {
    async getFriendRequests() {
      try {
        this.isLoading = true;
        const res = await this.$http.get("/student/friend-requests");
        this.friendRequests = res.data.data;
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async acceptRequest(requestId) {
      try {
        this.overlayLoading = true;
        const formData = new FormData();
        formData.append("is_accepted", 1);
        formData.append("receive_user_id", this.$store.state.userData.userId);
        await this.$http.post(
          `/student/friend-requests/${requestId}?_method=put`,
          formData
        );
        this.$helpers.makeToast(
          "success",
          this.$t("success"),
          this.$t("friend_request_has_been_accepted")
        );
        this.friendRequests = this.friendRequests.filter(
          (item) => item.id !== requestId
        );
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
    async rejectRequest(requestId) {
      try {
        this.overlayLoading = true;
        const formData = new FormData();
        formData.append("is_accepted", 0);
        formData.append("receive_user_id", this.$store.state.userData.userId);
        await this.$http.post(
          `/student/friend-requests/${requestId}?_method=put`,
          formData
        );
        this.$helpers.makeToast(
          "success",
          this.$t("success"),
          this.$t("friend_request_has_been_deleted")
        );
        this.friendRequests = this.friendRequests.filter(
          (item) => item.id !== requestId
        );
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./_index.scss";
</style>
